import { SelectHTMLAttributes } from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLButtonElement> {
  selectValue?: any;
  selectOptions?: any;
  label?: string;
  feedbackText?: string;
  feedbackState?: any;
  varient?: "small" | "medium" | "large";
  disabled?: boolean;
  className?: any;
}

export const Select = ({
  className = '',
  selectOptions,
  children,
  disabled,
  placeholder,
  label,
  feedbackState = '',
  feedbackText,
  varient = "medium",
  ...props
}: SelectProps) => {
  return (
    <div className={`form-group relative ${className}`}>
      {label && (
        <label className={`${disabled ? "text-theme-neutralLighter40" : ""}`}>
          {label}
        </label>
      )}
      <select 
        className={`form-select form-control mb-0 form-control-${varient} ${feedbackState}`}
        aria-label=""
        >
        {children}
    </select>
    {feedbackState && feedbackText && (
        <span className={feedbackState + " text-xs"}>{feedbackText}</span>
      )}
    </div>
  );
};
