// DONOT MODIFY THIS FILE GENERATED BY YARN

import * as React from 'react'

const iconList = [
    'alarm',
    'arrow-right',
    'back',
    'check-circle',
    'close',
    'exclamation-circle',
    'hexagon',
    'search',
]

export interface IconProps {
    color?: string
    className?: string
    height?: number
    kind?: string | any
    onClick?: () => void
    preview?: boolean
    size?: number
    style?: any
    width?: number
    wrapperStyle?: any
}
export default class Icon extends React.Component<IconProps, {}> {

    static defaultProps = {
        className: '',
        color: 'currentColor',
        size: 32
    }

    render() {
        const { kind, preview } = this.props
        return preview ? this.renderPreview() : this.renderIcon(kind)
    }

    renderPreview() {
        return (<div className="w-auto flex flex-wrap">{ iconList.map(kind => this.renderPreviewKind(kind)) }</div>)
    }

    renderIcon(kind: string) {
        const { wrapperStyle } = this.props
        if (wrapperStyle) {
            return (<div style={wrapperStyle}>{ this.getIcon(kind) }</div>)
        }
        return this.getIcon(kind)
    }

    renderPreviewKind(kind: string) {
        return (
             <div key={kind} className="w-1/4 px-2">
                <div className="p-4 my-2 bg-purple-100 text-purple-700 text-center rounded">
                    <p className="mb-3 flex justify-center">{this.renderIcon(kind)}</p>
                    <p>
                        <small>&lt;Icon kind="{kind}" /&gt;</small>
                    </p>
                </div>
            </div>
        )
    }

   

    getIcon(kind: string) {
        const { color, height, onClick, size, style, width, className, ...rest } = this.props

        switch (kind) {
            case ('alarm'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 16 16"><path fillRule="evenodd" d="M5.5.5A.5.5 0 0 1 6 0h4a.5.5 0 0 1 0 1H9v1.07a7.002 7.002 0 0 1 3.537 12.26l.817.816a.5.5 0 0 1-.708.708l-.924-.925A6.967 6.967 0 0 1 8 16a6.967 6.967 0 0 1-3.722-1.07l-.924.924a.5.5 0 0 1-.708-.708l.817-.816A7.002 7.002 0 0 1 7 2.07V1H5.999a.5.5 0 0 1-.5-.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1zm-5 4a.5.5 0 0 0-1 0v3.882l-1.447 2.894a.5.5 0 1 0 .894.448l1.5-3A.5.5 0 0 0 8.5 9V5z"/></svg>)
            case ('arrow-right'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 24 24"><path d="M14 5l7 7m0 0l-7 7m7-7H3" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>)
            case ('back'): return (<svg id="Layer_1" {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 16 16"><path d="M16 7.111H3.404L6.587 3.92 5.333 2.667 0 8l5.333 5.333 1.254-1.253-3.183-3.191H16z"/></svg>)
            case ('check-circle'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 24 24"><path d="M9 12l2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>)
            case ('close'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 16 16"><path d="M16 1.611L14.389 0 8 6.389 1.611 0 0 1.611 6.389 8 0 14.389 1.611 16 8 9.611 14.389 16 16 14.389 9.611 8z"/></svg>)
            case ('exclamation-circle'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 24 24"><path d="M12 8v4m0 4h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>)
            case ('hexagon'): return (<svg {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 65 74"><path d="M0 55.5v-37L33 0l32 18.5v37L33 74z"/><path d="M16 47.269V27.701l17.017-9.286L49 27.715V47.25l-15.984 9.3z"/><path d="M0 21.237l.97-1.7 26.238 14.11-.97 1.7z"/><path d="M23 33.849l.97-1.7 26.238 14.11-.97 1.7z"/><path d="M33 19.537l-16 8.731v18.433l16-8.731zM63 19.537l-16 8.731v19.4l16-8.731z"/><path d="M63 37.97l-16 8.731v18.433l16-8.731z"/><path d="M49 45.731l-16 8.731v18.433l16-8.731z"/></svg>)
            case ('search'): return (<svg id="Layer_1" {...rest} height={height || size} width={width || size} onClick={onClick} style={style} className={className} fill={color} viewBox="0 0 16 16"><path d="M11.435 10.063h-.723l-.256-.247a5.955 5.955 0 1 0-.64.64l.247.256v.723L14.637 16 16 14.637zm-5.489 0a4.117 4.117 0 1 1 4.117-4.117 4.111 4.111 0 0 1-4.117 4.117z"/></svg>)
            default: return null
        }
    }
}