import { format } from 'date-fns';
import React, { forwardRef, HTMLAttributes, useState } from 'react';
import DatePicker from "react-datepicker";
import { Button } from '../../atoms/button';
import { Input } from '../../atoms/input';

export interface DateSelectorProps extends HTMLAttributes<HTMLBaseElement> {
  size?: string;
  variant?: string;
  type?: string;
  isDisabled?: boolean;
  customInput?: any;
  isCustom?: boolean;
  popperClassName?: any;
  popperPlacement?: any;
  onDateChange?: any;
}

export const DateSelector = ({
  size = "small",
  variant = "primary",
  type = "rounded",
  isDisabled = false,
  customInput,
  isCustom,
  popperClassName,
  popperPlacement,
  ...props
}: DateSelectorProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e: any) => {
    setIsOpen(!isOpen);
    setStartDate(e);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      {isCustom ?
        <>
          <Button isRounded={true} className="mb-2" onClick={handleClick}>
            {format(startDate, "dd-MM-yyyy")}
          </Button>
          {isOpen && (
            <DatePicker selected={startDate} onChange={handleChange} inline disabled={isDisabled} />
          )}
        </>
        :
        <DatePicker
          selected={startDate}
          onChange={(date: any) => {
            setStartDate(date);
            props.onDateChange(date);
          }}
          closeOnScroll={true}
          calendarClassName="custom-calendar"
          className="form-control mb-0"
          showPopperArrow={false}
          popperClassName={popperClassName}
          popperPlacement={popperPlacement}
           disabled={isDisabled} 
        />
      }
    </>
  );
};
