import { Select } from "../../atoms/input/select";
import { DateSelector } from "../../molecules/datePicker";
import { FreeDeliveryCard } from "../../molecules/carCards/freeDeliveryCard";
import { CarInfoCard } from "../../molecules/carCards/carInfoCard";
import fetchMock from "fetch-mock";
import carData from "../../car-mock-data.json";
import { useState } from "react";


const options = ["Manhattan", "Brooklyn", "Queens", "Staten Island"]

export const CarSelectV2 = () => {
    fetchMock.get(
        "api/get_user_data",
        () => {
            console.log(carData);
            return carData;
        },
        {
            overwriteRoutes: true
        })

    const [toDate, setToDate] = useState<Date>(new Date());
    function dateChanged(event: any) {
        console.log(event);
        setToDate(event);
    }

    return (
        <div className="container mx-auto page-car-select py-4 bg-gray-100 flex h-full overflow-hidden">
            <div className="w-full px-2 flex flex-col h-full overflow-hidden">
                <div className="component-location-time-bar justify-between flex">
                    <Select varient="medium">
                        {options.map(option => {
                            return <option value={option} key={option}>{option}</option>
                        })}
                    </Select>
                    <div className="pl-auto flex">
                        <div className="pl-auto" style={{"padding-right": "0.5rem"} as any}>
                            <DateSelector isDisabled={true} popperClassName="" />
                        </div>
                        <div  style={{"margin": "5px"}}>&gt;</div>
                        <div className="pl-2">
                            <DateSelector onDateChange={dateChanged} popperClassName="" />
                        </div>
                    </div>


                </div>
                <div className="h-full overflow-auto">
                    {/* <FreeDeliveryCard /> */}
                    <CarInfoCard selectedDate={toDate} />
                </div>
            </div>
        </div>
    );
};
