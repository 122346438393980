interface CardFooterProps {
    cardFooterClass?: string
    children: any
}

export const CardFooter = ({
    cardFooterClass="p-4",
    children
}: CardFooterProps) => {
    return (
        <div className={`card-footer ${cardFooterClass}`}>
            {children}
        </div>
    )
}
