import './App.css';
import { CarSelectV2 } from './page/carSelect/carSelectV2';
import { Navbar } from './atoms/navbar';

function App() {
  return (
    <>
      <div className="page-container h-full overflow-hidden">
        <div className="page flex flex-col h-full overflow-hidden">
          <Navbar />
          <CarSelectV2 />
        </div>
      </div>

    </>
  );
}

export default App;
