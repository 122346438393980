
import { useEffect, useState } from 'react';
import { Button } from "../../atoms/button";
import { Card, CardBody, CardFooter } from "../../molecules/card";
import { Modal } from "../../molecules/modal/index";

import { LedgerServiceFactory } from "../../ledger-service"
import { Loader } from '../../atoms/loader';

export const CarInfoCard = (props: any) => {
  const [carsList, setCarsList] = useState<any[]>([]);
  const [modalData, setModalData] = useState({ isVisible: false, isSuccess: false, msg: "", txHash: "" });
  const [showLoader, setShowLoader] = useState(true);

  let ledgerService = LedgerServiceFactory.getInstance();

  useEffect(() => {
    ledgerService.initMetamask();
    fetch("api/get_user_data")
      .then((response) => response.json())
      .then((carsListData: any[]) => {
        setTimeout(() => {

          ledgerService.getSelectedAccount().then((selectedAccounts) => {

            let accountDataStr: string | null = localStorage.getItem(selectedAccounts[0]);
            let accountData: any = accountDataStr ? JSON.parse(accountDataStr) : undefined;

            let carsListPromise: Promise<any>;

            if (accountData
              && accountData.tokenIds
              && accountData.walletIdx != undefined
              && accountData.tokenIds.length > 0) {
              carsListPromise = listCarsFromLocalStore(carsListData, accountData);
            } else {
              carsListPromise = listNewMintedCars(carsListData, selectedAccounts[0]);
            }

            carsListPromise.then((updatedCarListData) => {
              setCarsList(updatedCarListData);
            }).finally(() => {
              setShowLoader(false);
            });

          }).catch(() => {
            setShowLoader(false);
          });
        });

      });
    // ledgerService.accountChange.subscribe(
    //   async () => {
    //     for (let i = 0; i < carsList.length; i++) {
    //       if (carsList[i].is_booked) {
    //         carsList[i].isRenter = await ledgerService.isRenter(carsList[i].id);
    //       }
    //     }
    //     setCarsList(carsList);
    //   }
    // );
  }, []);

  function listCarsFromLocalStore(carsListData: any[], accountData: any) {
    return new Promise<any>((resolve, reject) => {
      try {
        let totalCars = accountData.tokenIds.length;
        carsListData.splice(totalCars);

        ledgerService.setAdminWalletIndex(accountData.walletIdx);

        accountData.tokenIds.forEach((carId: string, idx: number) => {
          carsListData[idx].id = +carId;
        });

        ledgerService.fetchCarRentalData(carsListData, props.selectedDate).then(
          (updatedData) => {
            resolve(updatedData);
          }
        )
      } catch (ex) {
        reject(ex);
      }
    });
  }

  function listNewMintedCars(carsListData: any[], selectedAccount: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        ledgerService.mintCarList().then((mintReceipts) => {
          let totalCars = mintReceipts.length;
          carsListData.splice(totalCars);

          let tokenIds: any[] = [];

          mintReceipts.forEach((carData, idx) => {
            carsListData[idx].hourly_rent_price = carData.hourlyRentPrice;
            carsListData[idx].collateral = carData.collateralAmt;
            carsListData[idx].id = carData.receipt.events.Transfer.returnValues.tokenId;
            carsListData[idx].is_booked = false;

            tokenIds.push(carData.receipt.events.Transfer.returnValues.tokenId);
          });

          localStorage.setItem(selectedAccount, JSON.stringify({ tokenIds, walletIdx: ledgerService.getAdminWalletIndex() }));
          resolve(carsListData);
        });
      } catch (ex) {
        reject(ex);
      }
    });

  }

  async function returnCar(idx: number) {
    setShowLoader(true);

    try {
      let result = await ledgerService.returnCar(carsList[idx]);
      console.log(result);

      carsList[idx].reservationId = "";
      carsList[idx].is_booked = false;
      carsList[idx].isRenter = false;
      let dataUpdate: any[] = [];
      Object.assign(dataUpdate, carsList);
      setCarsList(dataUpdate);

      showModal(true, true, "Return Successfull!!", result.receipt.transactionHash);
    } catch (ex: any) {
      console.log(ex);
      showModal(true, false, "Return Failed!!", ex?.receipt?.transactionHash);
    }
    setShowLoader(false);
  }

  function showModal(isVisible: boolean, isSuccess: boolean, msg: string, txHash: string) {
    setModalData({ isVisible, isSuccess, msg, txHash });
  }

  async function bookReservation(idx: number) {
    setShowLoader(true);

    try {
      let result = await ledgerService.bookReservation(carsList[idx], props.selectedDate);
      console.log(result);

      carsList[idx].reservationId = result.reservationId;
      carsList[idx].is_booked = true;
      carsList[idx].isRenter = true;
      let dataUpdate: any[] = [];
      Object.assign(dataUpdate, carsList);
      setCarsList(dataUpdate);

      showModal(true, true, "Reservation Booked Successfully!!", result.receipt.transactionHash);
    } catch (ex: any) {
      console.log(ex);
      showModal(true, false, "Reservation Failed!!", ex?.receipt?.transactionHash);
    }
    setShowLoader(false);
  }

  const myData = carsList ? carsList.map((carDetails: any, idx: number) => {
    console.log('Car Info card rendered');
    return (
      <Card cardClass="flex bg-white my-2 rounded-lg overflow-hidden pl-4">
        <CardBody cardBodyClass="w-5/6 text-theme-neutralLighter70 pt-4 pb-2 flex">
          <div className="description order-none md:order-1 px-4 w-full">
            <div className="vehicle-name-place">
              <div className="bg-theme-neutralLighter10 text-theme-neutralLighter70 text-xs p-1">0.5 mi Church Street</div>
              <h3 className="text-xl font-semibold">{carDetails.car_type}</h3>
            </div>
            <div className="vehicle-type text-xs text-theme-neutralLighter40 mb-2">{carDetails.is_manual === true ? "Manual" : "Automatic"} . Petrol .{carDetails.seats_available} Seats</div>
            <div className="vehicle-extra-info text-xs border-t pt-2 border-theme-neutralLighter20">
              <div className="flex">
                <div>
                  <img className="w-4" alt="star-rating" src="https://zoomcar-assets.zoomcar.com/images/original/b04593cd90f850d14ed46ed739b0371457ef6834.png?1633947533" />
                </div>
                <div className="text pl-1">4.9 (51) · {carDetails.kms_driven} kms driven</div>
              </div>
              <div>
                Home Delivery & pickup available
              </div>
            </div>
          </div>
          <div className="display-img order-1 md:order-none">
            <img
              className="max-w-12.5"
              src={require('../../../public'+carDetails.imagePath).default}
              alt="" />
          </div>
        </CardBody>
        <CardFooter cardFooterClass="flex flex-col justify-between w-1/6 my-4">
          <div className="Price">
            {(carDetails.is_booked || carDetails.hourly_rent_price == 0) ? "" :
              ledgerService.calculateRentPrice(+carDetails.hourly_rent_price, +carDetails.collateral, Math.floor(new Date().getTime() / 1000), ledgerService.getEndDateSeconds(props.selectedDate)) + " Wei"
            }
          </div>
          <div className="book-vehicle">
            {carDetails.is_booked === true ? <Button onClick={() => returnCar(idx)} disabled={!carDetails.isRenter} variant="danger">Return Now</Button> : <Button onClick={() => bookReservation(idx)} variant="secondary">Book Now</Button>}
          </div>
        </CardFooter>
      </Card>
    )
  }) : <div>Data Not Available!!</div>

  return (
    <>
      {myData}
      {showLoader ? <Loader loadFullPage /> : <></>}
      <Modal
        isVisible={modalData.isVisible}
        onClose={() => showModal(false, false, "", "")}
        size='small'
        varient={modalData.isSuccess ? "success" : "danger"}
        showFooter
        footerChildren={
          <div>
            <Button
              size="small"
              variant={modalData.isSuccess ? "success" : "danger"}
              onClick={() => showModal(false, false, "", "")}
            >
              Close
            </Button>
          </div>
        }
      >
        <p>
          {modalData.msg}
        </p>
        {
          modalData.txHash && modalData.txHash != "" ?
            <p>
              Transaction Hash : <a title='View on block explorer' target="_blank" style={{ color: '#17b7df' }} href={"https://mumbai.polygonscan.com/tx/" + modalData.txHash}>{modalData.txHash}</a>
            </p>
            :
            <></>
        }

      </Modal>
    </>
  )
};
