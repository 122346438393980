import Web3 from 'web3';
import HDWalletProvider from "@truffle/hdwallet-provider";
import adminWallets from "./admin-wallets.json";
// import { Subject } from 'rxjs';

declare let window: any;
const rentalCarAbi = require('./assets/rental-car-contract-abi.json');
const DeployedContractAddress = '0xe91685558E621F9Be76d929F5d607635f8662d11';

const minHourlyRentPrice = 5;
const maxHourlyRentPrice = 15;

const minCollateral = 0;
const maxCollateral = 5;

const noOfCarsToList = 3;

export class LedgerServiceFactory {
    static instance: LedgerService;
    static getInstance() {
        if (!LedgerServiceFactory.instance) {
            LedgerServiceFactory.instance = new LedgerService();
        }
        return LedgerServiceFactory.instance;
    }
}

class LedgerService {

    web3!: Web3;
    selectedAccounts!: string[];
    private adminWalletIdx: number;
    // accountChange = new Subject<void>();

    constructor() {
        this.adminWalletIdx = this.getRandomInt(0, adminWallets.length)
    }

    public setAdminWalletIndex(idx: number) {
        this.adminWalletIdx = idx;
    }

    public getAdminWalletIndex() {
        return this.adminWalletIdx;
    }

    async fetchCarRentalData(data: any[], reservationEndDate: Date) {
        for (let i = 0; i < data.length; i++) {
            const result = await this.getCarMetadata(data[i].id);
            data[i].hourly_rent_price = result.hourlyRentPrice;
            data[i].collateral = result.collateral;
            data[i].is_booked = !(await this.checkAvailability(data[i], reservationEndDate));
            if (data[i].is_booked) {
                data[i].isRenter = await this.isRenter(data[i].id);
                data[i].reservationId = await this.getReservationId(data[i].id);
            }
        }
        return data;
    }

    private async isRenter(tokenId: number) {
        let renter = await this.getRenterOfToken(tokenId);
        const selectedAccount = await this.getSelectedAccount();
        return renter.toUpperCase() == selectedAccount[0].toUpperCase();
    }

    private async getWeb3Instance(): Promise<Web3> {
        if (this.web3 == undefined) {
            if (window.ethereum) {
                return new Web3(window.ethereum);
            } else {
                throw new Error("Metamask not detected!!. Please connect to metamask wallet");
            }
        }
        return this.web3;
    }

    async getTotalSupply() {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        let result = await rentalCarContract.methods.totalSupply().call({ from: selectedAccount[0] });

        return result ? parseInt(result) : undefined;
    }

    private async getReservationId(tokenId: number) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        let timeNow = new Date();

        let timeNowSeconds = Math.floor(timeNow.getTime() / 1000);

        return rentalCarContract.methods.getReservationId(tokenId, timeNowSeconds).call({ from: selectedAccount[0] });
    }

    private async getRenterOfToken(tokenId: number) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        let timeNow = new Date();

        let timeNowSeconds = Math.floor(timeNow.getTime() / 1000);

        return rentalCarContract.methods.renterOf(tokenId, timeNowSeconds).call({ from: selectedAccount[0] });
    }

    private async getCarMetadata(tokenId: number) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        return rentalCarContract.methods.rentalCarMeta(tokenId).call({ from: selectedAccount[0] });
    }

    async getSelectedAccount(): Promise<string[]> {
        if (this.selectedAccounts) {
            return this.selectedAccounts;
        }
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            this.selectedAccounts = accounts;
        } else {
            throw new Error("Metamask not detected!!. Please connect to metamask wallet");
        }
        return this.selectedAccounts;
    }

    async initMetamask() {
        this.getSelectedAccount();
        window.ethereum.on('accountsChanged', (accounts: string[]) => {
            this.handleAccountChange(accounts);
            console.log("Selected accounts Changed to - ", this.selectedAccounts);
        });

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (chainId != '0x13881') {
            alert("Please connect to Polygon Mumbai testnet");
        }

        window.ethereum.on('chainChanged', (chainId: any) => {
            if (chainId != '0x13881') {
                alert("Please connect to Polygon Mumbai testnet");
            }
        });
    }

    private async handleAccountChange(accounts: string[]) {
        this.selectedAccounts = accounts;
        // this.accountChange.next();
    }

    getNoOfHours(startTime: number, endTime: number) {
        return Math.ceil((endTime - startTime) / 3600);
    }

    calculateRentPrice(hourlyPrice: number, collateral: number, startTime: number, endTime: number) {
        return this.getNoOfHours(startTime, endTime) * hourlyPrice + collateral;
    }

    getEndDateSeconds(selectedDate: Date) {
        selectedDate.setHours(23);
        selectedDate.setMinutes(59);
        selectedDate.setSeconds(59);
        return Math.floor(selectedDate.getTime() / 1000);
    }

    private async checkAvailability(carDetails: any, reservationEndDate: Date) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        let timeNow = new Date();

        let timeNowSeconds = Math.floor(timeNow.getTime() / 1000);
        let endTimeSeconds = this.getEndDateSeconds(reservationEndDate);

        return rentalCarContract.methods.isAvailable(carDetails.id, timeNowSeconds, endTimeSeconds)
            .call({
                from: selectedAccount[0],
            });
    }

    private async completeReservation(carDetails: any) {

        let carOwnerPrvKey = adminWallets[this.adminWalletIdx].privateKey;
        let carOwnerAddress = adminWallets[this.adminWalletIdx].address;

        const provider = new HDWalletProvider(carOwnerPrvKey, window.ethereum);
        let web3 = new Web3(provider);

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        return new Promise((resolve, reject) => {
            try {
                rentalCarContract.methods.completeReservation(carDetails.id, carDetails.reservationId)
                    .send({
                        from: carOwnerAddress
                    }).on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', resolve)
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        });

    }

    async returnCar(carDetails: any) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        return new Promise<any>((resolve, reject) => {
            try {
                rentalCarContract.methods.returnCar(carDetails.reservationId)
                    .send({
                        from: selectedAccount[0]
                    }).on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (returnReceipt: any) => {
                        console.log(returnReceipt);
                        this.completeReservation(carDetails).then((reserveCompleteReceipt) => resolve({ receipt: returnReceipt }));
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        });

    }

    private async pickUpCar(reservationId: number) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        return new Promise((resolve, reject) => {
            try {
                rentalCarContract.methods.pickUpCar(reservationId)
                    .send({
                        from: selectedAccount[0]
                    }).on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', resolve)
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    async bookReservation(carDetails: any, reservationEndDate: Date) {
        let web3 = await this.getWeb3Instance();

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        const selectedAccount = await this.getSelectedAccount();

        let timeNow = new Date();

        let timeNowSeconds = Math.floor(timeNow.getTime() / 1000);
        let endTimeSeconds = this.getEndDateSeconds(reservationEndDate);
        let rentPrice = this.calculateRentPrice(+carDetails.hourly_rent_price, +carDetails.collateral, timeNowSeconds, endTimeSeconds);

        return new Promise<any>((resolve, reject) => {
            try {
                rentalCarContract.methods.reserve(carDetails.id, timeNowSeconds, endTimeSeconds)
                    .send({
                        from: selectedAccount[0],
                        value: rentPrice
                    }).on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (reserveReceipt: any) => {
                        console.log('receipt', reserveReceipt);
                        let reservationId = reserveReceipt.events.Transfer.returnValues.tokenId;
                        this.pickUpCar(reservationId).then((pickUpReceipt) => resolve({ receipt: reserveReceipt, reservationId }));
                    })
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    async mintCarList(): Promise<any[]> {
        let mintReceipts = [];
        for (let i = 0; i < noOfCarsToList; i++) {
            mintReceipts.push(await this.mintCRT());
        }
        return mintReceipts;
    }

    private async mintCRT(): Promise<any> {

        let carOwnerPrvKey = adminWallets[this.adminWalletIdx].privateKey;
        let carOwnerAddress = adminWallets[this.adminWalletIdx].address;

        const provider = new HDWalletProvider(carOwnerPrvKey, window.ethereum);
        let web3 = new Web3(provider);

        let rentalCarContract = new web3.eth.Contract(rentalCarAbi, DeployedContractAddress);

        let hourlyRentPrice = this.getRandomInt(minHourlyRentPrice, maxHourlyRentPrice);
        let collateralAmt = this.getRandomInt(minCollateral, maxCollateral);

        return new Promise((resolve, reject) => {
            try {
                rentalCarContract.methods.mint(hourlyRentPrice, collateralAmt)
                    .send({
                        from: carOwnerAddress
                    }).on('transactionHash', (hash: any) => {
                        console.log('transactionHash', hash);
                    })
                    .on('receipt', (mintReceipt: any) => resolve({ receipt: mintReceipt, hourlyRentPrice, collateralAmt }))
                    .on('error', (error: any, receipt: any) => { reject({ error, receipt }) });
            } catch (ex) {
                reject(ex);
            }
        });
    }

    private getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min); //Both maximum and minimum are inclusive
    }
}
