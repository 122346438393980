import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: String;
  variant?: String;
  isRounded?: Boolean;
  isActive?: Boolean;
}

export const Button = ({
  size = "small",
  variant = "primary",
  children,
  disabled,
  className,
  isRounded,
  isActive,
  ...props
}: ButtonProps) => {
  return variant !== "link" ? (
    <button
      className={classNames(
        variant === "primary" && !disabled
          ? "bg-theme-primary text-theme-white hover:bg-theme-btnHover active:bg-theme-clicked"
          : variant === "primary" &&
              "bg-theme-disabled text-theme-neutralLighter60 pointer-events-none",
        variant === "secondary" && !disabled
          ? "border border-theme-primary hover:bg-theme-clicked hover:text-theme-white text-theme-primary active:bg-theme-clicked active:text-theme-white"
          : variant === "secondary" &&
              "border border-theme-neutralLighter20 text-theme-neutralLighter30 pointer-events-none",
        variant === "danger" && !disabled
        ? "border border-theme-danger hover:bg-theme-danger hover:text-theme-white text-theme-danger active:bg-theme-danger active:text-theme-white"
        : variant === "danger" &&
            "border border-theme-neutralLighter20 text-theme-neutralLighter30 pointer-events-none",
        variant === "success" && !disabled
        ? "border border-theme-success hover:bg-theme-success hover:text-theme-white text-theme-success active:bg-theme-success active:text-theme-white"
        : variant === "success" &&
            "border border-theme-neutralLighter20 text-theme-neutralLighter30 pointer-events-none",
        size === "small" && "py-1.5 px-4 text-sm",
        size === "medium" && "py-2 px-6 text-base",
        size === "large" && "py-3 px-8 text-base",
        className,
        isActive &&
          (variant === "primary"
            ? "bg-theme-clicked hover:bg-theme-btnHover font-semibold"
            : variant === "secondary" &&
              "bg-theme-clicked hover:bg-theme-btnHover text-theme-white font-semibold"),
        isRounded && "rounded",
        "rounded font-light btn"
      )}
      {...props}
    >
      {children}
    </button>
  ) : (
    <button
      className={classNames(
        !disabled
          ? "text-theme-primary  hover:underline hover:text-theme-btnHover active:text-theme-clicked active:no-underline cursor-pointer"
          : "text-theme-disabled pointer-events-none",
        size === "small" ? "text-sm" : "text-base",
        className,
        "font-light hover:underline",
      )}
    >
      {children}
    </button>
  );
};
